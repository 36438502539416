footer {
  box-sizing: border-box;
  height: var(--footer--height);
  padding-bottom: 40px;
  text-align: center;
  width: 100%;
}

footer #logotipo-sp {
  margin: 0 auto;
  padding: 15px;
  text-align: center;
  width: 15%;
  display: flex;
  justify-content: center;
}

footer a {
  margin-left: 50px;
  margin-right: 50px;
}

footer img {
  height: auto;
  max-width: 100%;
  margin-left: 50px;
  margin-right: 50px;
}

@media(max-width: 850px) {
  footer #logotipo-sp {
    width: 40%;
  }
}

@media(max-width: 480px) {
  footer #logotipo-sp {
    width: 40%;
  }
}