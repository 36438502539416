header {
  align-items: center;
  display: flex;
  height: var(--header--height);
  justify-content: space-between;
  width: 100%;
}

.header__logo {
  padding-left: 22px;
  width: 10%;
}

@media(max-width: 768px) {
  .header__logo {
    padding-left: 22px;
    width: 30%;
  }
}

.header__logo img {
  animation: fadein 1s;
  display: none;
  height: auto;
  max-width: 100%;
}

@keyframes fadein {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}
