.loading {
  align-items: center;
  animation: 1s fadein;
  background: rgba(0, 0, 0, 0.8);
  display: none;
  left: 0;
  height: 100%;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.loading span {
  color: var(--lime);
  font-size: 2.5rem;
  text-shadow: 2px 2px 8px var(--light);
}