/* Configurações gerais do sidebar */
.sidebar {
    background-color: var(--lime);
    background-image: url(../img/menu-bg@2x.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
    overflow-x: hidden; /* Disable horizontal scroll */
    position: fixed;
    right: 0;
    top: 0;
    transition: 0.5s; /* 0.5s second transition effect to slide in the sidebar */
    width: 0;
    z-index: 1;
}
  
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .sidebar {
        background-image: url(../img/menu-bg@1x.png);
    }
}

/* Sidebar links */
.sidebar__links a {
    color: var(--dark);
    display: block;
    font-size: 1rem;
    font-weight: 400;
    padding: 1.5rem 2rem 1.5rem 2rem;
    text-decoration: none;
    transition: 0.3s;
    cursor: pointer;
}

.sidebar__links {
    margin-top: 15vh;
}

.sidebar__button {
    padding: 22px;
    transition: margin-left .5s;
    display: flex;
    align-items: center;
}
  
.sidebar__button--open,
.sidebar__button--close {
    cursor: pointer;
    display: inline-block;
}

.sidebar__button--open span {
    background-color: var(--lime);
    display: block;
    height: 6px;
    margin-bottom: 6px;
    width: 30px;
}

.sidebar__text--open span {
    color: var(--lime);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    height: 6px;
    margin-bottom: 10px;
    margin-right: 10px;
    width: 30px;
}

.sidebar__button--open span:last-child {
    margin-bottom: 0px;
}

.sidebar--opened {
    width: 287px;
}

.sidebar__button--close {
    padding: 22px;
    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(90deg);
}

.sidebar__button--close span {
    background-color: var(--dark);
    display: block;
    height: 6px;
    margin-bottom: 6px;
    width: 30px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
    .sidebar {padding-top: 15px;}
    .sidebar a {font-size: 18px;}
}

/* Rodapé */
.sidebar__footer {
    padding: 20px;
    text-align: right;
}

@media(max-width: 850px) {
    nav {
        margin-top: 10px;
    }
}