#landing-page {
  align-items: center;
  animation: fadein 1s;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

@keyframes fadein {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

/* Logotipo da landing page */
#landing-page #logotipo {
  width: 30%;
}

#landing-page #sobre {
  width: 90%;
  color: var(--white);
  text-align: center;
}

#landing-page #sobre__button {
  width: 60%;
}

#sobre__button-abrir-modal {
  margin-top: 10px;
  width: 100%;
  height: 30px;
  background-color: var(--lime);
  border-radius: 5px;
  border: 0px;
  color: var(--dark);
  font-weight: bold;
  cursor: pointer;
}

#sobre__modal {
  background: rgba(0, 0, 0, 0.8);
  display: none;
  left: 0;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
}

#landing-page #logotipo img {
  height: auto;
  max-width: 100%;
}

#logotipo h1 {
  margin-top: 0px;
}

@media(max-width: 480px) {
  #landing-page #logotipo {
    width: 80%;
  }
}