.modal {
  animation: fadein 1s;
  background-color: var(--white);
  border: 1px solid var(--light-blue);
  border-radius: 7px;
  box-sizing: border-box;
  max-height: 95vh;
  margin: 15px auto;
  overflow-y: initial !important;
  width: 65vw;
}

@keyframes fadein {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.modal__header {
  align-items: center;
  background-color: var(--light-blue);
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
}

.modal__header__saudacao {
  font-weight: 700;
  text-transform: uppercase;
}

.modal__header__social {
  display: flex;
  flex-direction: column;
  gap: 0;
  justify-content: space-between;
}

.modal__header__social__title {
  font-size: 0.6rem;
  font-weight: 700;
  margin-bottom: 6px;
  text-transform: uppercase;
}

.modal__header__social__buttons ul {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.modal__header__social__buttons li {
  list-style: none;
}

.modal__header__social__twitter {
  cursor: pointer;
}

.modal__header__social__whatsapp {
  cursor: pointer;
}

.modal__header__social__linkedin {
  cursor: pointer;
}

.modal__header__social__facebook {
  cursor: pointer;
}

.modal__header__social__mobile {
  display: none;
}

@media(max-width: 768px){
  .modal__header__social__twitter {
    display: none;
  }
  
  .modal__header__social__whatsapp {
    display: none;
  }
  
  .modal__header__social__linkedin {
    display: none;
  }
  
  .modal__header__social__facebook {
    display: none;
  }

  .modal__header__social__mobile {
    display: flex;
  }

  .modal__header__social__buttons ul {
    justify-content: center;
  }

  .modal__header__social {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 0px;
  }

  .modal__header__social__title {
    padding-right: 10px;
  }
}

.modal__close {
  color: var(--light-blue);
  cursor: pointer;
  font-size: 3rem;
  font-weight: 700;
  margin-right: 10px;
  position: absolute;
  right: 0;
  top: 0;
}

.modal__footer {
  align-items: center;
  background-color: var(--light-blue);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  display: flex;
  justify-content: center;
  padding: 15px;
}

.modal__footer__close {
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 700;
}

.modal__body {
  max-height: calc(95vh - 110px);
  overflow-y: auto;
}

.modal__body__introducao {
  align-items: center;
  background-color: var(--gray);
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.modal__body__introducao__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal__body__introducao__info span {
  font-weight: 400;
  padding: 5px;
}

.modal__body__introducao__sobre {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40%;
}

@media(max-width: 480px) {
  .modal {
    width: 100%;
  }

  .modal__body__introducao {
    background-color: var(--gray);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
  }

  .modal__body__introducao__info {
    width: 100%;
  }

  .modal__body__introducao__sobre {
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
  }

  .modal__close {
    color: var(--dark);
  }

  .modal__header__social {
    margin-right: 28px;
  }

  .modal__body__introducao__sobre__title {
    margin-left: 5px;
  }

  .contribuicao__resposta__orgaos {
    flex-wrap: wrap;
    gap: 10px;
  }

  .contribuicao__resposta__orgaos__subprefeitura {
    width: 100%;
  }
}

.modal__body__introducao__sobre__title {
  font-size: 0.6rem;
  font-weight: 700;
  margin-bottom: 12px;
  text-transform: uppercase;
}

.modal__body__introducao__sobre__categorias {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.modal__body__contribuicao__title,
.modal__body__respostas__title {
  background-color: var(--light-blue);
  font-weight: 700;
  padding: 5px;
  text-transform: uppercase;
  text-align: center;
}

.modal__body__contribuicao__integra-reduzida,
.modal__body__contribuicao__integra-completa,
.modal__body__respostas__conteudo {
  font-size: 0.9rem;
  font-weight: 400px;
  line-height: 1.5rem;
  padding: 15px;
  text-align: justify;
}

.modal__body__contribuicao__integra-completa {
  display: none;
}

.modal__body__contribuicao__opcoes--expandir,
.modal__body__contribuicao__opcoes--recolher {
  color: var(--green-blue);
  cursor: pointer;
  display: none;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
}

.modal-sobre {
  animation: fadein 1s;
  background-color: var(--lime);
  border-radius: 7px;
  box-sizing: border-box;
  margin: 15px auto;
  overflow-y: initial !important;
  width: 65vw;
}

.modal-sobre__body {
  max-height: calc(95vh - 40px);
  overflow-y: auto;
}

.modal-sobre__close {
  color: var(--lime);
  cursor: pointer;
  font-size: 3rem;
  font-weight: 700;
  margin-right: 10px;
  position: absolute;
  right: 0;
  top: 0;
}

.modal__section-sobre {
  background-color: var(--lime);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 50px;
  background-image: url(../img/bg-modal-sobre.png);
}

.modal__section-sobre a {
  margin-top: 50px;
  align-self: center;
}

.modal-sobre__footer {
  align-items: center;
  background-color: var(--dark);
  color: var(--lime);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  display: flex;
  justify-content: center;
  padding: 15px;
}

.modal-sobre__footer__close {
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 700;
}

@media(max-width: 480px) {
  .modal-sobre {
    width: 100%;
  }

  .modal__section-sobre {
    padding: 10px;
  }

  .modal-sobre__close {
    color: var(--dark);
  }
}
