#resultado-pesquisa {
  animation: fadein 1s;
  box-sizing: border-box;
  display: none;
  height: 100%;
  justify-content: space-between;
}

@keyframes fadein {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

#resultado-pesquisa aside {
  background-color: var(--light-gray);
  box-sizing: border-box;
  flex-grow: 1;
  padding: 15px;
  overflow: auto;
}

#sidebar-pesquisar__nav {
  display: none;
}

@media(max-width: 850px) {
  #resultado-pesquisa aside {
    display: none;
  }

  #sidebar-pesquisar__nav {
    bottom: 0px;
    display: flex;
    position: fixed;
    margin-bottom: 65px;
    z-index: 9999;
  }

  /* Configurações gerais do sidebar */
  .sidebar-pesquisar {
    background-color: var(--lime);
    background-image: url(../img/menu-bg@2x.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
    overflow-x: hidden; /* Disable horizontal scroll */
    position: fixed;
    left: 0;
    top: 0;
    transition: 0.5s; /* 0.5s second transition effect to slide in the sidebar */
    width: 0;
    z-index: 1;
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .sidebar-pesquisar {
        background-image: url(../img/menu-bg@1x.png);
    }
  }

  /* Sidebar links */
  .sidebar-pesquisar__form {
    margin-top: 7vh;
  }

  .sidebar-pesquisar__button {
    transition: margin-left .5s;
    background-color: var(--lime);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  .sidebar-pesquisar__button--open,
  .sidebar-pesquisar__button--close {
    cursor: pointer;
    display: inline-block;
  }

  .sidebar-pesquisar__button--open span {
    background-color: var(--lime);
    display: block;
    height: 6px;
    margin-bottom: 6px;
    width: 30px;
  }

  .sidebar-pesquisar__button--open span:last-child {
    margin-bottom: 0px;
  }

  .sidebar-pesquisar--opened {
    width: 287px;
  }

  .sidebar-pesquisar__button--close {
    padding: 0px 10px;
    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(90deg);
    color: var(--dark);
    font-size: 4rem;
    font-weight: 700;
  }

  .sidebar-pesquisar__button--close span {
    background-color: var(--dark);
    display: block;
    height: 6px;
    margin-bottom: 6px;
    width: 30px;
  }

  /* Rodapé */
  .sidebar-pesquisar__footer {
    padding: 20px;
    text-align: right;
  }
}

#resultado-pesquisa__filtros {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

#resultado-pesquisa__filtros fieldset {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--dark);
  margin: 15px;
  font-size: 0.85rem;
  display: flex;
  flex-wrap: wrap;
}

#resultado-pesquisa__filtros fieldset select {
  width: 95%;
  background-color: transparent;
  color: var(--dark);
  border: 0px;
  align-items: center;
}

#resultado-pesquisa__filtros fieldset div {
  color: var(--dark);
  cursor: pointer;
  font-size: 1.03rem;
  font-weight: 700;
  margin-top: 1px;
}

#resultado-pesquisa__filtros fieldset input {
  background-color: transparent;
  border: 0px;
  color: var(--black);
  line-height: 1.4rem;
  margin-top: 10px;
  width: 100%;
}

.resultado-pesquisa__text ::-webkit-input-placeholder {
  color: var(--dark);
}

#resultado-pesquisa__button,
#sidebar-resultado-pesquisa__button {
  align-self: center;
  background-color: var(--dark);
  border: 0px;
  border-radius: 5px;
  color: var(--lime);
  cursor: pointer;
  font-weight: bold;
  height: 30px;
  margin-bottom: 15px;
  margin-top: 10px;
  width: 90%;
}

#resultado-pesquisa__conteudo {
  background-color: var(--white);
  box-sizing: border-box;
  flex-grow: 10;
  padding: 15px;
  min-width: 80%;
  overflow: auto;
}

.resultado-pesquisa__erro-nao-encontrado {
  display: none;
  font-size: 1.5rem;
}

.resultado-pesquisa__erro-outros {
  color: var(--red);
  display: none;
  font-size: 1.5rem;
}

.resultado-pesquisa__resultado__exibir-mais {
  cursor: pointer;
  text-align: center;
}

#resultado-pesquisa__modal {
  background: rgba(0, 0, 0, 0.8);
  display: none;
  left: 0;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
}