:root {
  --header--height: 90px;
  --footer--height: 90px;
  --main--height: calc(100vh - var(--header--height) - var(--footer--height));
}

html, body {
  box-sizing: border-box;
  color: var(--dark);
  font-family: 'Open Sans';
  /* min-height: 675px; */
  max-height: 100vh;
}

body {
  align-items: center;
  background-attachment: fixed;
  background-color: var(--dark-blue);
  background-image: url(../img/bg@2x.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: none;
  flex-direction: column;
  justify-content: space-between;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  body {
      background-image: url(../img/bg@1x.png);
  }
}

@media (-webkit-min-device-pixel-ratio: 6.25), (min-resolution: 600dpi) {
  body {
    background-image: url(../img/bg@3x.png);
  }
}
/*
http://emag.governoeletronico.gov.br/
O conteúdo é removido de sua posição, não ocupando espaço e é movido para “fora da tela”, ficando oculto.
Os leitores de tela acessam o conteúdo.
*/
.somente-acessibilidade {
  left: -999em;
  position: absolute;
}

@media(max-width: 850px) {
  html, body {
    min-height: 100vh;
  }
}

@media(max-width: 480px) {
  :root {
    --header--height: 70px;
  }

  html, body {
    min-height: 100vh;
    max-height: 100%;
  }
}