.contribuicao {
  border: 1px solid var(--light-blue);
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 18px;
}

.contribuicao__body {
  display: flex;
  justify-content: space-between;
  padding: 9px 10px 10px 14px;
}

@media(max-width: 768px) {
  .contribuicao__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .contribuicao__body__middle,
  .contribuicao__body__right{
    margin-top: 10px;
  }
}

.contribuicao__footer {
  background-color: var(--light-blue);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 0.875rem;
  font-weight: 700;
  padding: 5px;
  text-align: center;
}

.contribuicao__footer--link {
  cursor: pointer;
}

.contribuicao__body__left,
.contribuicao__body__middle {
  display: flex;
  flex-direction: column;
  min-width: 30%;
}

.contribuicao__body__right {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  min-width: 40%;
}

.contribuicao__nome--label,
.contribuicao__canal--label {
  font-size: 0.7rem;
  font-weight: 700;
  margin-bottom: 4px;
}

.contribuicao__nome--valor,
.contribuicao__canal--valor {
  font-size: 1rem;
  font-weight: 700;
}

.contribuicao__categoria {
  background-color: var(--light-blue);
  border: 1px solid var(--light-blue);
  border-radius: 5px;
  box-sizing: border-box;
  color: var(--dark);
  font-weight: bold;
  font-size: 0.8rem;
  line-height: 14px;
  max-height: 22px;
  padding: 2px 10px;
}

.contribuicao__resposta {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contribuicao__resposta__subtema--label,
.contribuicao__resposta__devolutiva--label,
.contribuicao__resposta__orgaos__subprefeitura--label,
.contribuicao__resposta__orgaos__secretaria--label,
.contribuicao__resposta__eixo--label {
  font-weight: 700;
}

.contribuicao__resposta__orgaos {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.contribuicao__resposta--separator {
  border: 1px solid var(--light-blue);
}

.contribuicao__resposta__subtema,
.contribuicao__resposta__resposta,
.contribuicao__resposta__orgaos,
.contribuicao__resposta__eixo {
  padding: 5px 0px;
}

.contribuicao__resposta__orgaos__subprefeitura,
.contribuicao__resposta__orgaos__secretaria {
  width: 50%;
  padding: 5px 0px;
}

.contribuicao__resposta__orgaos__secretaria {
  padding-bottom: 0px;
}