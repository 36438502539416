.form-pesquisa--section {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  width: 60%;
}

fieldset {
  border: 0;
}

.form-pesquisa--form {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.form-pesquisa__pesquisa-textual--component {
  width: 100%;
  border-width: 1px;
  border-bottom: 0px;
  border-style: solid;
  border-color: var(--light-blue);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.form-pesquisa__pesquisa-textual--component fieldset {
  color: var(--white);
  display: flex;
  flex-direction: column;
  padding: 2px 0px 0px 0.5px;
}

.form-pesquisa--form label {
  font-size: 0.9rem;
  font-weight: 700;
}

.form-pesquisa__pesquisa-textual--component input {
  background-color: transparent;
  border: 0px;
  color: var(--light-blue);
  line-height: 1.5rem;
  margin-top: 5px;
}

.form-pesquisa__pesquisa-textual--component ::-webkit-input-placeholder {
  color: transparent;
}

.form-pesquisa--select {
  width: 25%;
  margin: 0px;
}

.form-pesquisa--select fieldset {
  align-items: left;
  border-width: 1px;
  border-style: solid;
  border-color: var(--light-blue);
  box-sizing: border-box;
  color: var(--white);
  display: flex;
  flex-wrap: wrap;
  font-size: 0.8rem;
  height: 100%;
  margin: 0px;
  padding-left: 1.5px;
}

.form-pesquisa--select fieldset select {
  align-items: center;
  background-color: transparent;
  border: 0px;
  color: var(--light-blue);
  /* font-size: 1rem; */
  width: 93%;
}

.form-pesquisa--select--limpar {
  color: var(--light-blue);
  cursor: pointer;
  font-size: 1.03rem;
  font-weight: 700;
  margin-top: 1px;
}

.form-pesquisa--select fieldset select option {
  color: var(--black);
  border-radius: 3px;
}

select-categoria fieldset {
  border-bottom-left-radius: 5px;
}

select-secretaria fieldset {
  border-bottom-right-radius: 5px;
}

#pesquisa__button {
  margin-top: 40px;
  width: 100%;
  height: 30px;
  background-color: var(--light-blue);
  border-radius: 5px;
  border: 0px;
  color: var(--dark);
  font-weight: bold;
  cursor: pointer;
}

@media(max-width: 1024px) {
  .form-pesquisa--section {
    margin-top: 10px;
    width: 80%;
  }

  .form-pesquisa--select {
    width: 50%;
    margin: 0px;
  }

  select-subprefeitura fieldset {
    border-bottom-left-radius: 5px;
  }

  select-secretaria fieldset {
    border-bottom-right-radius: 5px;
  }
}

@media(max-width: 480px) {
  .form-pesquisa--section {
    margin-top: 10px;
    width: 80%;
  }
  
  .form-pesquisa--select {
    width: 100%;
    margin: 0px;
  }

  .form-pesquisa--select--limpar {
    font-size: 1.2rem;
  }

  select-secretaria fieldset {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
