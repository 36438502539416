main {
  height: var(--main--height);
  /* min-height: 555px; */
  overflow: auto;
  width: 100%;
}

/* @media(max-width: 480px) {
  main {
    height: var(--main--height);
    min-height: 380px;
    overflow: auto;
    width: 100%;
  }
}

@media(max-width: 850px) {
  main {
    height: var(--main--height);
    min-height: 200px;
    overflow: auto;
  }
} */